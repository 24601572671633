import React from 'react';

import Layout from '../components/Layout';

import { StaticImage } from 'gatsby-plugin-image';

const Events = (props) => (
  <Layout fullMenu location={props.location} title="Schedule of Events">
    <article id="main">
      <header>
        <h2>Events</h2>
        <p>Saturday, December 18th, 2021</p>
      </header>
      <section className="wrapper style5 events">
        <div className="inner">
          <div className="row">
            <div className="col-6 col-12-medium">
              <h3>Wedding Ceremony</h3>
              <p>
                Vietnamese Martyrs Catholic Church - <b>2:00pm</b>
                <br />
                <a
                  href="https://goo.gl/maps/CC3ojopNz9UxiocH7"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  801 E Mayfield Rd
                  <br />
                  Arlington, TX 76014
                </a>
                <br />
                TNTT members: Please wear uniform
              </p>
            </div>
            <div className="col-6 col-12-medium">
              <span className="image fit eventimage">
                <StaticImage
                  src="../assets/images/church.jpg"
                  alt="church"
                  placeholder="blurred"
                />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-12-medium">
              <h3>Reception</h3>

              <p>
                Thanh Thanh Restaurant - <b>6:00pm</b>
                <br />
                <a
                  href="https://goo.gl/maps/3m1jvbYjdGpedygk7"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  2515 E Arkansas Ln #150
                  <br />
                  Arlington, TX 76010
                </a>
                <br />
              </p>
            </div>
            <div className="col-6 col-12-medium">
              <span className="image fit eventimage">
                <StaticImage
                  src="../assets/images/thanhthanh.jpg"
                  alt="reception"
                  placeholder="blurred"
                />
              </span>
            </div>
          </div>

          <hr />

          {/* <h4>Lodging</h4>
          <p>We have reserved rooms at this hotel, please let them know</p> */}
        </div>
      </section>
    </article>
  </Layout>
);

export default Events;
